export const ApiProfile = {
    DevApplicantV1: 'http://52.66.72.6:8080/campus-placement-dev/api/v1/',
    IntApplicantV1: 'http://52.66.72.6:8080/campus-placement-int/api/v1/',
    UatApplicantV1: 'http://52.66.72.6:8080/campus-placement-uat/api/v1/',
    // DevApplicantV1: 'http://3.108.227.133:8080/campus-placement-dev/api/v1/',
    // IntApplicantV1: 'http://3.108.227.133:8080/campus-placement-int/api/v1/',
    // UatApplicantV1: 'http://3.108.227.133:8080/campus-placement-uat/api/v1/',
    ProdApplicantV1: 'https://admin.campusplacement.in/campus-placement/api/v1/',
    ProdRecruiterV1: 'https://co.campusplacement.in/campus-placement/api/v1/',
}

export const WebProfile = {
    DevRecruiterV1: 'http://52.66.72.6:8080/campus_dev_coordinator/',
    IntRecruiterV1: 'http://52.66.72.6:8080/campus_int_coordinator/',
    UatRecruiterV1: 'http://52.66.72.6:8080/campus_uat_coordinator/',
    // DevRecruiterV1: 'http://3.108.227.133:8080/campus_dev_coordinator/',
    // IntRecruiterV1: 'http://3.108.227.133:8080/campus_int_coordinator/',
    // UatRecruiterV1: 'http://3.108.227.133:8080/campus_uat_coordinator/',
    ProdRecruiterV1: 'https://co.campusplacement.in/',

    DevLeaderV1: 'http://52.66.72.6:8080/campus_dev_manager/',
    IntLeaderV1: 'http://52.66.72.6:8080/campus_int_manager/',
    UatLeaderV1: 'http://52.66.72.6:8080/campus_uat_manager/',
    // DevLeaderV1: 'http://3.108.227.133:8080/campus_dev_manager/',
    // IntLeaderV1: 'http://3.108.227.133:8080/campus_int_manager/',
    // UatLeaderV1: 'http://3.108.227.133:8080/campus_uat_manager/',
    ProdLeaderV1: 'https://admin.campusplacement.in/',

    DevAdminV1: 'http://52.66.72.6:8080/campus_dev_admin/',
    IntAdminV1: 'http://52.66.72.6:8080/campus_int_admin/',
    UatAdminV1: 'http://52.66.72.6:8080/campus_uat_admin/',
    // DevAdminV1: 'http://3.108.227.133:8080/campus_dev_admin/',
    // IntAdminV1: 'http://3.108.227.133:8080/campus_int_admin/',
    // UatAdminV1: 'http://3.108.227.133:8080/campus_uat_admin/',
    ProdAdminV1: 'https://admin.campusplacement.in/',

    DevApplicantV1: 'http://localhost:3000/campus_int_student#/',
    IntApplicantV1: 'http://52.66.72.6:8080/campus_int_student/',
    UatApplicantV1: 'http://52.66.72.6:8080/campus_uat_student/',
    // IntApplicantV1: 'http://3.108.227.133:8080/campus_int_student/',
    // UatApplicantV1: 'http://3.108.227.133:8080/campus_uat_student/',
    ProdApplicantV1: 'https://student.campusplacement.in/',

}

export const StudentDeepLinking = {
    DevV1: 'http://localhost:3000/quikhire_int_applicant#/',
    IntV1: 'http://52.66.72.6:8080/campus_int_student/#/',
    UatV1: 'http://52.66.72.6:8080/campus_uat_student/#/',
    // IntV1: 'http://3.108.227.133:8080/campus_int_student/#/',
    // UatV1: 'http://3.108.227.133:8080/campus_uat_student/#/',
    ProdV1: 'https://student.campusplacement.in/#/',
}
export const companyJobLink = {
    DevV1: 'http://52.66.72.6:8080/job_dev/#/jobDetails/',
    IntV1: 'http://52.66.72.6:8080/job_int/#/jobDetails/',
    UatV1: 'http://52.66.72.6:8080/job_uat/#/jobDetails/',
    // DevV1: 'http://3.108.227.133:8080/job_dev/#/jobDetails/',
    // IntV1: 'http://3.108.227.133:8080/job_int/#/jobDetails/',
    // UatV1: 'http://3.108.227.133:8080/job_uat/#/jobDetails/',
    ProdV1: 'https://campusplacement.in/jobs/#/jobDetails/',
}


export const ResumeBuilderAPIProfile = {
    DevV1: 'http://localhost:8080/',
    IntV1: 'http://13.52.157.11:8080/resume-builder/api/v1/',
    UatV1: '',
    ProdV1: 'https://api.shareresume.co/api/v1/'
}

export const ResumeBuilderWebProfile = {
    DevV1: 'http://13.52.157.11:8080/resume_builder_int/#/',
    IntV1: 'http://13.52.157.11:8080/resume_builder_int/#/',
    UatV1: '',
    ProdV1: 'https://www.shareresume.co/#/',
}


export const getBaseURLTonavigatetoLogin = (profile, role) => {
    if (profile.toLocaleLowerCase().includes('dev')) {
        if (role == "ROLE_RECRUTER") {
            let dataURL = WebProfile.DevRecruiterV1
            return (dataURL);
        } else if (role == "ROLE_MANAGER") {
            let dataURL = WebProfile.DevAdminV1
            return (dataURL);
        } else if (role == "ROLE_ADMIN") {
            let dataURL = WebProfile.DevAdminV1
            return (dataURL);
        } else if (role == "ROLE_APPLICANT") {
            let dataURL = WebProfile.DevApplicantV1
            return (dataURL);
        }
    }
    if (profile.toLocaleLowerCase().includes('int')) { 
        if (role == "ROLE_RECRUTER") {
            let dataURL = WebProfile.IntRecruiterV1
            return (dataURL);
        } else if (role == "ROLE_MANAGER") {
            let dataURL = WebProfile.IntAdminV1
            return (dataURL);
        } else if (role == "ROLE_ADMIN") {
            let dataURL = WebProfile.IntAdminV1
            return (dataURL);
        } else if (role == "ROLE_APPLICANT") {
            let dataURL = WebProfile.IntApplicantV1
            return (dataURL);
        }
    }
     
    if (profile.toLocaleLowerCase().includes('uat')) {
        if (role == "ROLE_RECRUTER") {
            let dataURL = WebProfile.UatRecruiterV1
            return (dataURL);
        } else if (role == "ROLE_MANAGER") {
            let dataURL = WebProfile.UatAdminV1
            return (dataURL);
        } else if (role == "ROLE_ADMIN") {
            let dataURL = WebProfile.UatAdminV1
            return (dataURL);
        } else if (role == "ROLE_APPLICANT") {
            let dataURL = WebProfile.UatApplicantV1
            return (dataURL);
        }
    }
    if (profile.toLocaleLowerCase().includes('prod')) {
        if (role == "ROLE_RECRUTER") {
            let dataURL = WebProfile.ProdRecruiterV1
            return (dataURL);
        } else if (role == "ROLE_MANAGER") {
            let dataURL = WebProfile.ProdAdminV1
            return (dataURL);
        } else if (role == "ROLE_ADMIN") {
            let dataURL = WebProfile.ProdAdminV1
            return (dataURL);
        } else if (role == "ROLE_APPLICANT") {
            let dataURL = WebProfile.ProdApplicantV1
            return (dataURL);
        }
    }
}
